@use '../../../assets/project/components/common/variable.scss' as *;

.c-els-card--no-padding {
  max-width: 500px;
  word-break: break-word;
}

.c-els-card__content-title {
  text-decoration: underline;
}
