.planning-hospital-stay {
  &__sections-container {
    h4 {
      margin-bottom: 0.5rem;
    }
  }
  textarea {
    max-width: 31.25rem;
  }
}
