@use '../../../assets/project/components/common/variable.scss' as *;

.c-sticky {
  position: fixed;
  top: 0;
  z-index: $z-index-header;
}

.page-header {
  height: $header-height;
  position: sticky;
  top: 0;
  z-index: $z-index-header;
  background-color: white;
  border-bottom: 1px solid $els-color-n3;

  & &__header {
    height: $header-height;
    box-sizing: content-box;

    &__content {
      padding: 1.5rem 2.5rem;

      @include mq($until: desktop) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
      @include mq($until: tablet) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }

  &__header--fix {
    border-bottom: 1px solid #cecece;
    position: fixed;
  }

  &__header--scroll {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }

  &__close-wrapper {
    cursor: pointer;
    padding: 0.5rem;
    border: 1px solid transparent;
    background-color: transparent;

    &:hover {
      border: 1px solid $els-color-primary !important;
    }
    &:hover svg {
      color: $els-color-primary !important;
    }
  }
}
