@use '../../../../assets/project/components/common/variable.scss' as *;

.clinical-setup {
  &__header {
    padding-bottom: 0.5rem;
  }

  &__avatar-wrapper {
    display: inline-block;
    text-align: left;
    margin-bottom: 1.5rem;
    @include mq($from: tablet) {
      display: block;
      padding-left: 1rem;
    }
  }

  &__avatar-text {
    text-align: left;
    @include mq($from: tablet) {
      text-align: center;
    }
  }

  &__control--short {
    width: 60px;
  }

  &__control--medium {
    width: 200px;
  }

  &__patient {
    @include mq($until: tablet) {
      display: block;
    }
  }

  &__patient-input {
    order: 1;
  }

  &__patient-avatar {
    order: 2;
  }

  &__patient-sex, &__patient-gender, &__patient-mrn {
    justify-content: unset;
  }

  &__patient-sex-info, &__patient-gender-info, &__patient-mrn-info {
    margin-right: 100px;
    width: 200px;
  }

  &__patient-specify-below, &__patient-pronouns, &__patient-room {
    width: 200px;
  }

  &__patient-specify-below {
    margin-right: 30px;
  }

  .chart-field {
    &-clinicalUnit {
      > div:last-child {
        flex-wrap: wrap;
        & > div {
          flex: 0 50%;
        }
      }
    }
    &-clinicalFirstDay,
    &-patientDateOfBirth,
    &-patientSex,
    &-providerFirstLastInitial {
      .c-els-field {
        width: 200px;
      }
    }

    &-patientIdentifier {
      .c-els-field {
        width: 60px;
      }
    }
  }
}
