@use '../../../../assets/project/components/common/variable.scss' as *;

.vital-signs-common {
  .chart-input {
    &__header {
      padding-bottom: 0.5rem;
      font-size: $els-fs-h1;
    }
    &__section-title,
    &__section-title--no-bottom {
      font-size: 20px;
      margin-top: 3rem;
      margin-bottom: 0.5rem;
    }
    &__note-section-title {
      font-size: 20px;
      margin-bottom: 0.5rem;
    }
    &__section-title--first {
      margin-top: 2rem;
    }
    &__section-title--no-bottom {
      margin-bottom: 0;
    }
    &__section-title--inline {
      font-size: 20px;
      line-height: 28px;
    }
    &__label--inline-section-title {
      line-height: 28px;
    }
    &__error--pull-right {
      width: 100px;
    }
    &__input,
    &__input--pull-right {
      width: 80px;
    }
    &__input--other {
      width: 200px;
    }
    &__input--pull-right {
      margin-right: 1rem;
    }
    &__dropdown,
    &__dropdown--pull-right {
      width: 200px;
    }
    &__dropdown--pull-right {
      margin-right: 1rem;
    }
    &__table-graph-container {
      padding-left: 3rem;
      min-width: 150px;
      @include mq($until: wide) {
        padding-left: 0;
      }
      @include mq($until: desktop) {
        padding-left: 0;
      }
      @include mq($until: tablet) {
        padding-left: 0;
      }
      @include mq($until: mobile) {
        padding-left: 0;
      }
    }
    &__scroll-table-container {
      width: 250px;
      overflow-x: hidden;
    }
    &__adds--note {
      font-size: 0.875rem;
      font-style: italic;
    }
    .error-required {
      min-width: 85px;
    }
  }
}
