@use '../../../assets/project/components/common/variable.scss' as *;

.title-tooltip {
  &__text {
    font-size: $els-fs-h4;
  }
  &__icon-wrapper {
    margin-left: 0.5rem;
  }
  &__icon-wrapper:hover {
    color: $els-color-primary;
  }
  &__line-height-h3 {
    line-height: 32px;
  }
}
