@use '../../../assets/project/components/common/variable.scss' as *;

.scale {
  &__score {
    text-align: right;
    align-self: center;
    > span {
      background: $els-color-n1;
      padding: 0.5rem 2.5rem;
    }
  }
  &__question {
    width: 80%;
    padding-bottom: 1rem;
  }
  &__horizontal {
    display: inline-flex;
    > fieldset {
      display: contents;
    }
  }
  &__vertically {
    display: inline-block;
    padding-bottom: 1rem;
    > div:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}
