@use '../../../assets/project/components/common/variable.scss' as *;

.assessment-statistic {
  background-color: $els-color-n0;
  padding: $els-space-1o2;

  &__card-title {
    position: relative;
  }
  &__card-value {
    font-size: $els-fs-meta;
    &::after {
      content: '';
      display: inline-block;
    }
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: -$els-space-1o4;
    transform: translate(-100%, -50%);
    display: flex;
    height: 16px;
    width: 16px;
    align-items: center;
    justify-content: center;
    background-color: $els-color-confirm-on-dark;
    border-radius: 50%;
  }

  .clock {
    margin-right: $els-space-1o4;
  }
}
