.els-scheduling {
  &-date-picker-container {
    margin-left: 2.5rem;

    & + .c-els-field--checkbox {
      margin-left: 2.5rem;
      width: 21.5rem;
    }
  }
}
