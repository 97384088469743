@use '../../../assets/project/components/common/variable.scss' as *;

.els-stepper {
  background-color: $els-color-n0;
  display: flex;
  justify-content: center;
  padding: 1.25rem 0;

  &-item {
    margin-right: 8.75rem;
    position: relative;
    @include mq($until: tablet) {
      margin-right: 5.25rem;
    }

    // add divider with :before on all .els-stepper-item elements except for the first one
    &:not(:first-child):before {
      background-color: $els-color-n3;
      content: '';
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateX(-6.875rem);
      width: 5rem;
      @include mq($until: tablet) {
        transform: translateX(-4.125rem);
        width: 3rem;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &-item-container {
    display: flex;
  }

  &-item-indicator-container {
    align-items: center;
    border-radius: 1.5rem;
    border: 2px solid $els-color-n3;
    display: flex;
    height: 3rem;
    min-height: 3rem;
    justify-content: center;
    margin-right: 0.8125rem;
    width: 3rem;
    min-width: 3rem;
  }

  &-item-indicator {
    color: $els-color-n9;
  }

  &-item--completed {
    .els-stepper-item-indicator-container {
      background-color: transparent;
      border-color: $els-color-secondary;
      position: relative;

      .els-stepper-item-completed-checkmark {
        align-items: center;
        background-color: $els-color-confirm-on-dark;
        border-radius: 0.5rem;
        bottom: -3px;
        display: block;
        display: flex;
        height: 1rem;
        justify-content: center;
        position: absolute;
        right: -3px;
        width: 1rem;
      }
    }

    .els-stepper-item-indicator {
      color: $els-color-n9;
    }
  }

  &-item--active {
    .els-stepper-item-indicator-container {
      background-color: $els-color-n10;
      border-color: $els-color-n0;
      position: relative;

      &:after {
        background-color: transparent;
        border-radius: 1.75rem;
        border: 2px solid $els-color-primary;
        bottom: auto;
        content: '';
        display: block;
        height: 3.5rem;
        min-height: 3.5rem;
        position: absolute;
        right: auto;
        width: 3.5rem;
        min-width: 3.5rem;
      }
    }

    .els-stepper-item-indicator {
      color: white;
    }
  }

  &-item-content {
    margin-top: 0.5rem;
  }

  &-item-completed-step-link {
    display: inline-block;
    max-width: 6.875rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
