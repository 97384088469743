@use '../../../assets/project/components/common/variable.scss' as *;

.chart-input {
  &__field {
    .chart-input__toggle {
      max-width: 500px;
    }

    &--has-errors {
      .chart-input__label {
        color: $els-color-warn;
      }

      .c-els-field__input {
        border-bottom: 2px solid $els-color-warn;
      }

      .o-els-icon-svg {
        color: $els-color-warn;
      }
    }
  }

  &__label {
    margin-right: 1rem;

    &-asterisk {
      color: $els-color-warn;
    }

    &-tooltip {
      max-width: 40%;
      .c-els-flyout__close {
        margin-left: 1rem;
      }
    }

    &-scale {
      display: inline-block;
    }
  }

  &__multi-select-dropdown .sng-multi-select-search {
    margin-top: 0;
  }

  &__multi-select_radio.sng-multi-select-search {
    margin-top: 0;
  }

  &__field.error-field--no-bottom {
    margin-bottom: 0 !important;
  }

  &__dropdown {
    max-width: 500px !important;
  }

  &__textbox {
    max-width: 500px !important;
  }

  &__textarea {
    &--fixed-max-width {
      max-width: 500px !important;
    }
  }
}

.time-field-container {
  width: 200px;
}
