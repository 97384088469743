@use '../../../../../assets/project/components/common/variable.scss' as *;

.case-study-content {
  margin-bottom: 8rem;

  .patient-avatar {
    width: $els-space-3x;
    height: $els-space-3x;
    margin-right: $els-space-1o2;

    .avatar__name {
      font-size: $els-fs-base;
      line-height: $els-space-2x * 1.4;
    }
  }

  .patient-name {
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .quick-view {
    &__patient-info {
      width: 442px;
      justify-content: space-between;
    }

    &__tab-group {
      position: relative;
    }

    &__avatar {
      position: absolute;
      top: -10px;
      right: 60px;
      background-color: white;
    }

    &__icon {
      display: inline-block;
      position: relative;
      top: 5px;
      margin-left: 5px;
    }

    &__tab-body {
      max-width: 80%;
    }
  }

  .comingSoonStyle {
    color: lightgrey !important;
    background: transparent;
    border-color: transparent;
    cursor: not-allowed;
  }
}
