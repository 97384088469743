.recharts-label {
  fill: #505050;

  &.recharts-text {
    text-anchor: middle;
  }
}

.chart-tooltip {
  &__wrapper {
    background-color: black;
    color: white;
    font-size: 14px;
  }
}

.graph-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}
