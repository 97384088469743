@use '../../../assets/project/components/common/variable.scss' as *;

.edit-chart-section {
  &__table {
    margin-bottom: 2rem;
    // delete column width
    .c-els-table__row .c-els-table__cell:first-child {
      width: 20%;
    }
    .c-els-table__row .c-els-table__cell:nth-child(2) {
      vertical-align: middle;
    }
    .c-els-table__row .c-els-table__cell:nth-child(3) {
      width: 30px;
    }
    .header-title {
      font-weight: bolder;
      width: 0;
      white-space: nowrap;
    }
    thead {
      tr {
        th {
          box-shadow: none;
          padding-top: 0;
        }
      }
    }
  }

  .status-badge {
    background-color: $badge-green;
    margin-top: $els-space-1o2;

    &--active {
      background-color: $badge-orange;
    }
  }
}
