@use '../../../assets/project/components/common/variable.scss' as *;

.error-tooltip {
  margin-top: 0.5rem;
  position: absolute;

  .o-els-icon-svg {
    cursor: pointer;
  }

  &--expanded {
    background-color: rgba(220, 220, 220, 0.5);
    margin-top: 1.5625rem;
    padding: 1rem;
    position: absolute;
    width: fit-content;
    max-width: 350px;
    z-index: 2;

    &:after {
      content: '';
      display: block;
      position: absolute;
      background-color: $els-color-warn;
      height: 2px;
      top: -2px;
      right: 0;
      width: calc(100% - 1.5rem);
    }
  }

  &__arrow-wrapper {
    position: absolute;
    width: 1.5625rem;
    height: 1.5625rem;
    top: -1.5625rem;
    left: 0;
    overflow: hidden;

    &:after {
      display: block;
      content: '';
      width: 3.125rem;
      height: 0.125rem;
      -webkit-transform: translate;
      transform: rotate(45deg);
      background-color: $els-color-warn;
      position: absolute;
      top: 0.75rem;
      left: -0.75rem;
    }

    .error-tooltip__arrow {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      border-bottom: 25px solid rgba(220, 220, 220, 0.5);
      border-right: 25px solid transparent;
    }
  }

  &__content {
    background-color: white;
    position: relative;
    padding: 1.25rem;
    padding-right: 3rem;
  }

  &__close-btn {
    cursor: pointer;
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
  }

  .error-message {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
