@use '../../../../../assets/project/components/common/variable.scss' as *;

.psychosocial-assessment {
  .sng-multi-select-search {
    max-width: max-content;
  }

  .score-tag {
    padding: 0.5rem 1.5rem;
    background-color: $els-color-extended-blue-0;
    color: $els-color-extended-blue-9;
  }

  .special-character {
    color: $els-color-secondary-on-dark;
    margin-right: 13px;
  }

  .rick-score-notes {
    max-width: 500px;
  }
}
