.sensory-assessment {
  .c-els-divider {
    margin-top: 3rem !important;
    margin-bottom: 2rem !important;
  }

  .chart-input__label {
    margin-right: 0;
  }

  .size-field-unit {
    position: relative;
    bottom: -0.8rem;
    margin-left: 0.5rem;
  }

  &__text-field {
    margin-right: 1rem;
  }

  &__size-field {
    max-width: 80px;
  }
}
