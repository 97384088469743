@use '../../../../../assets/project/components/common/variable.scss' as *;

.newborn-assessment {
  .chart-input__textbox {
    width: 133px;
  }
  .c-dropdown-search {
    width: 200px;
  }

  .scale-score {
    display: flex;
    .score-tag {
      margin-left: auto;
      padding: 0.5rem 1.5rem;
      background-color: $els-color-extended-blue-0;
      color: $els-color-extended-blue-9;
    }
  }

  &__elimination {
    display: flex;
    align-items: baseline;
    width: 130px;

    .chart-input__field {
      margin-left: auto;
    }
  }
}
