@use '../../../../assets/project/components/common/variable.scss' as *;

.page-wrapper {
  display: flex;
  flex-direction: column;

  .nav--empty {
    width: 256px;
  }
  .c-els-footer.u-els-margin-left {
    margin-left: 0 !important;
  }
  .c-els-footer.u-els-margin-right {
    margin-right: 0 !important;
  }

  .c-els-footer__item {
    white-space: normal;
  }

  #nav-toggle {
    padding: 0.4rem 0.5rem 0.2rem 0.5rem;
    margin-left: -0.5rem;
    box-sizing: content-box !important;
    cursor: pointer;
    &:hover {
      background-color: $els-color-n0;
    }
  }

  &__breadcrumbs-bar {
    height: $height-breadcrumbs-bar;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 1rem 0;
  }

  &__assignment-bar-wrapper {
    position: sticky;
    top: $header-height;
    z-index: $z-index-header;
  }

  &__children-wrapper {
    position: relative;
    min-height: 500px;
  }
}

@media (min-width: 900px) {
  .page-wrapper__nav-and-content {
    justify-content: flex-start !important;
  }
}
