@use '../../../../../assets/project/components/common/variable.scss' as *;

.saved-injections {
  .saved-patient-charting__badge {
    &--active {
      background-color: $els-color-primary-on-dark;
    }
  }
  .edit-chart-section {
    .status-badge--active {
      background-color: $els-color-primary-on-dark;
    }
  }
}
