@use '../../../../../assets/project/components/common/variable.scss' as *;

.assignment-selection {
  padding: 0 1.5625rem 4.375rem;

  &-tiles {
    display: flex;
    justify-content: center;

    @include mq($until: tablet) {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
