.wounds {
  &__sections-container {
    h4 {
      margin-bottom: 1rem;
    }
  }
  &__category,
  &__type {
    .chart-input__field--has-errors .error-tooltip {
      position: relative;
    }
  }
}
