@use '../../../../assets/project/components/common/variable.scss' as *;

.care-plan-manager {
  &__page {
    .sng-accordion {
      .creator-name,
      .priority {
        display: inline;
        font-size: 16px;
      }

      .c-els-field__input {
        &:hover {
          border-color: transparent;
        }
      }

      .resolved {
        background-color: $badge-green;
      }
      .intervention-list,
      .action-list {
        padding: 0 1rem;
        margin-left: 0.5rem;
        li {
          list-style: disc;
        }
      }
    }
    .sidebar__container {
      width: calc(100% - #{$width-left-nav});
      max-width: calc(100% - #{$width-left-nav});
    }

    .care-plan-document {
      .chart-input__textbox {
        max-width: 100% !important;
      }
      &__display-none {
        display: none;
      }
    }
  }
}
