@use '@els/els-styleguide-core/scss/tools' as *;
@use '@els/els-styleguide-table/scss/table';

.c-els-vitalSigns-data-table {
  @extend .c-els-table;
  table-layout: fixed;
  min-width: $els-breakpoint-desktop;

  &--deleted {
    text-decoration: line-through;
  }

  &__container {
    overflow-x: auto;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__cell {
    @extend .c-els-table__cell;
    border-right: 1px solid $els-color-n2;

    &--header {
      @extend .c-els-table__cell--header;
      word-wrap: break-word;
    }

    &--min-width {
      min-width: 1%;
    }
  }

  &__adds-score {
    background-color: $els-color-n1;

    &--text-secondary {
      color: $els-color-secondary;
    }

    &--bg-emergency-call {
      background-color: $els-color-extended-purple-0;
    }
  }
}
