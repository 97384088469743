.genitourinary-assessment {
  // fix issue calendar icons of next treatment are overlayed on last treatment popup
  .upper-date {
    .c-datepicker-icon {
      z-index: 3;
    }
    .c-datepicker-input {
      z-index: 2;
    }
  }
}
