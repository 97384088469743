.edinburgh-postnatal-depression-scale {
  .instruction-using-edinburgh li {
    list-style: auto;
    margin-left: 1em;
  }

  .related-charts__references p {
    margin-block-start: 1em;
  }
}
