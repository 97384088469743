@use '../../../assets/project/components/common/variable.scss' as *;

.feedback {
  display: flex;
}

.assignment-submission {
  max-width: 1200px;
  margin: 0 auto;

  &__hightlighted-result {
    display: flex;
    flex-wrap: wrap;
  }

  &__link-chart-btn {
    &:disabled {
      color: $els-color-n3;
      &:hover {
        border-color: white;
        & .c-els-button__text {
          border-color: white;
        }
      }
    }
  }

  &__overall-comments {
    max-width: 800px;
  }

  &--information-hollow {
    vertical-align: middle;
    margin-right: 8px;
  }
  .quiz-questions-result {
    &__title {
      font-size: $els-fs-h2;
    }
  }
}
