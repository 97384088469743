.general-orders {
  &__chart-input {
    width: 500px;

    .c-dropdown-search {
      z-index: 4;
    }

    .c-datepicker-icon {
      z-index: 3;
    }
    .c-datepicker-input {
      z-index: 2;
    }

    .chart-input__field--has-errors .error-tooltip {
      position: relative;
    }
  }

  .general-orders-table {
    &__order-time-field {
      min-width: 100px;
    }
  }
}
