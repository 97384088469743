@use '../../../assets/project/components/common/variable.scss' as *;

.save-cancel__footer {
  background-color: $els-color-n0;
  border-top: $els-thick-keyline-width solid $els-color-n2;
  bottom: 0;
  padding: 1.5rem 2rem;
  position: fixed;
  width: 100%;
  z-index: 1;
  left: 0;
  .c-els-button--link {
    font-size: 1.25rem;
  }
}
