@use '/src/assets/project/components/common/variable.scss' as *;
@use './utilities' as *;

body.scroll-bounce-custom {
  overflow: hidden;
}

body.scroll-bounce-custom > #app {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

textarea.sng-textarea-12x {
  height: 12rem;
}

.sng-form-buttons {
  margin-top: 3rem;

  .c-els-button {
    min-width: 100px;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  .o-els-flex-layout__item:not(:last-child) {
    .c-els-button {
      margin-right: 1rem;
    }
  }
}

.sng-accordion {
  .c-els-accordion__group {
    &--expanded {
      .c-els-accordion__head {
        border-bottom: 1px solid $els-color-primary;
      }
    }
    .c-els-accordion {
      &__button {
        padding: 1rem 0 0.75rem 1rem;
        @include mq($until: tablet) {
          .u-text-ellipsis {
            @include multiLineEllipsis($maxLines: 2);
          }
        }
        @include mq($from: desktop) {
          .u-text-ellipsis {
            @include multiLineEllipsis($maxLines: 1);
          }
        }
      }

      &__button-text {
        margin-left: $els-space-1x;
      }
    }
  }
}

.sng-headline {
  background-color: #fef0e4;
  margin: 2rem 0;
  padding: 1rem;
}

.sng-article {
  border-width: 2px 0;
  border-style: solid;
  border-color: $els-color-n2;
  margin-top: 3rem;
  margin-bottom: 1rem;
  padding: 1rem 0 0 0;
}

.date-picker-container {
  max-width: 170px;
}

.tooltip-overlay-header {
  z-index: 9999;
}

.limit-max-width {
  max-width: 80% !important;
}

.saved-patient-charting {
  &__status {
    display: flex;

    .saved-patient-charting__badge {
      background-color: $badge-green;
      margin-top: $els-space-1o2;

      &--active {
        background-color: $badge-orange;
      }
    }

    .saved-patient-charting__update-btn {
      margin-left: $els-space-1x;
    }
  }

  &__action {
    display: flex;

    .saved-patient-charting__trash-icon {
      margin-left: $els-space-1x;
    }
  }
}

.c-els-table {
  > .c-els-table__body {
    > .c-els-table__row {
      color: $els-color-n7;
    }
  }
}

.c-dropdown-search {
  &__option {
    z-index: $z-index-dropdown;
  }
}
