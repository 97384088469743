@use '../../../../../assets/project/components/common/variable.scss' as *;

.assignment-selection-tile {
  -webkit-appearance: none !important;
  background-color: white;
  border: 2px solid white;
  box-shadow: 0 2px 10px 8px rgba(170, 170, 170, 0.1);
  cursor: pointer;
  margin: 0 2.1875rem 4.375rem;
  padding: 2.75rem;
  text-align: center;
  max-width: 315px;
  color: $els-color-n9;

  @include mq($until: tablet) {
    margin: 0 0 4.375rem;
    flex-basis: 45%;
    max-width: none;
  }

  &:hover {
    border-color: $els-color-primary;
  }

  &:disabled {
    border: none;
  }

  &--selected {
    border-color: $els-color-primary;
    position: relative;
  }

  &__img {
    margin-bottom: 1.125rem;
    width: 10.625rem;
  }

  &__title {
    font-size: 1.5rem;
  }

  &__description {
    font-size: 0.875rem;
    line-height: 21px;
  }

  &__checkmark {
    align-items: center;
    background-color: $els-color-primary;
    border-radius: 0.9375rem;
    display: flex;
    height: 1.875rem;
    justify-content: center;
    left: calc(50% - 0.9375rem);
    position: absolute;
    top: -15px;
    width: 1.875rem;
  }
}
