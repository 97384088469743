.sng-error-bar {
  width: 100%;
  max-height: 80px;
  overflow: auto;
  position: fixed;
  bottom: 0;
  color: lightgrey;
  opacity: 0.7;
  z-index: 99999;
}
