@use '../../../assets/project/components/common/variable.scss' as *;

.legend {
  padding: 2rem 0;
  margin-left: 1.5rem;

  &__dot {
    display: inline-block;
    height: 10px;
    width: 10px;
    margin-right: 0.5rem;
    border-radius: 50%;
  }

  &__text {
    font-size: $els-fs-meta;
    margin-top: 4px;
  }
}

.grade {
  display: flex;
}
