@use '../../../assets/project/components/common/variable.scss' as *;

.tile {
  width: 340px;
  height: 280px;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  &:not(.tile--active) {
    &:before {
      content: '';
      position: absolute;
      border: 5px dashed #e7d5c9;
      top: -4px;
      bottom: -4px;
      left: -4px;
      right: -4px;
    }

    &:hover:before {
      border-color: $els-color-primary;
    }
  }

  &--active {
    box-shadow: 0 0 30px 0 rgba(231, 213, 201, 0.9);
    .tile__content-wrapper {
      &:before {
        content: '';
        height: 100%;
        position: absolute;
        border-left: solid 4px $els-color-secondary;
        top: 0;
        left: 1px;
      }
    }

    &:before {
      content: '';
      position: absolute;
      border: solid 1px rgba(80, 80, 80, 0.1);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &:hover:before {
      border: solid 2px $els-color-primary;
    }
  }

  &__icon-wrapper {
    position: absolute;
    width: 42px;
    height: 42px;
    top: 1rem;
    right: 1rem;
    border: solid 1px $els-color-primary;
    border-radius: 50%;
  }

  &__content-wrapper {
    position: relative;
    margin-top: 7rem !important;
    padding: 0 2.5rem !important;
    color: $els-color-secondary;
  }

  &__title {
    display: flex;
    flex: 0;
    font-size: 32px;
    line-height: 1;
    text-transform: uppercase;
  }
}
