.navigate-component {
  display: flex;
  align-items: center;

  .navigate-text {
    font-size: 1.5rem;
  }

  .navigate-stage {
    margin-right: 10px;
    margin-left: 10px;
  }
}

@media (max-width: 1300px) {
  .navigate-text {
    font-size: 20px !important;
  }
}

@media (max-width: 992px) {
  .navigate-text {
    font-size: 16px !important;
  }
  .navigate-stage {
    margin-left: 5px !important;
    margin-right: 0 !important;
  }
}

@media (max-width: 828px) {
  .navigate-text {
    font-size: 12px !important;
  }
}
