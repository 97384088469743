@use '../../../../../assets/project/components/common/variable.scss' as *;

.musculoskeletal-assessment {
  .column-left {
    order: 1;
  }
  .column-right {
    order: 1;
    @include mq($until: tablet) {
      order: 2;
    }
  }
}
