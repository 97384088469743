@use '../../../../../assets/project/components/common/variable.scss' as *;

.respiratory-assessment {
  .c-els-field {
    max-width: 500px;
  }

  .info-text {
    border-style: solid;
    border-width: 0.025rem;
    border-color: $els-color-primary;

    .o-els-flex-layout {
      margin: 1rem 0.5rem;
    }
  }

  .o-els-flex-layout {
    .sng-form-buttons {
      margin-top: 0;
    }
  }

  .text-box {
    max-width: 80px;
    margin-bottom: -1rem !important;

    .chart-input__label {
      width: max-content;
    }
  }

  .amount-oxygen .c-els-field--radio {
    margin-bottom: 2rem;
  }
}
