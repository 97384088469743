.pain-assessment {
  .horizontal .u-flex-column {
    display: -webkit-box;

    .o-els-flex-layout__item {
      margin-right: 1rem;
    }
  }

  .related-charts {
    margin-bottom: 3rem;
  }
  &__credit {
    margin-bottom: 4rem;
  }

  &__intensity-of-pain {
    .c-els-field__label-text {
      padding-left: 1.5rem;
    }
  }
}
