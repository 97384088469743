@use '../../../../assets/project/components/common/variable.scss' as *;

.line-chart-tooltip {
  &__wrapper {
    width: fit-content;
    background-color: black;
    color: white;
    font-size: 14px;
    position: absolute;
    top: 0;
    padding: $els-space-1x;
    padding-right: $els-space-2x;

    .o-els-icon-svg {
      position: absolute;
      cursor: pointer;
      right: 15px;
    }

    .u-els-bold::after {
      content: '';
      position: absolute;
      top: 15px;
      right: 100%;
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent black transparent transparent;
    }
  }
}

.line-chart {
  position: relative;

  .recharts-tooltip-wrapper {
    display: none;
  }
}
