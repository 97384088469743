@use '../../../assets/project/components/common/variable.scss' as *;

.avatar {
  &__container {
    width: 116px;
    height: 116px;
    vertical-align: middle;
    border-radius: 50%;
    color: white;
    background-color: $els-color-n10;
    text-align: center;
  }
  &__name {
    font-size: 56px;
    font-weight: bold;
    padding-top: 5px;
    line-height: 116px;
    text-transform: uppercase;
  }
  &__image {
    line-height: 114px;
    background-color: white;
    border: 1px solid $els-color-n2;
    border-radius: 50%;

    img {
      width: 72px;
      height: 72px;
    }
  }
}
