.column {
  &__bg-level {
    width: 160px;
  }
  &__bg-action {
    width: 400px;
  }
  &__meal {
    width: 150px;
  }
  &__entry-by {
    width: 150px;
  }
}
