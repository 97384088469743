@use '../../../assets/project/components/common/variable.scss' as *;

.case-study-quiz {
  margin-bottom: $els-space-3x;

  .c-els-divider {
    margin-left: -$els-space-2x;
    margin-right: -$els-space-2x;
  }

  &__question {
    margin-top: $els-space-3x;

    .rationale--incorrect {
      font-size: $els-fs-h4;
    }

    // adjust margins around rationale section provided by assessment question components
    form + div {
      margin-top: $els-space-1x !important;
      margin-bottom: $els-space-2x !important;

      & > div {
        margin-top: 0 !important;
      }
    }

    .c-els-field--radio,
    .c-els-field--checkbox {
      margin-top: 0 !important;
    }
  }
}
