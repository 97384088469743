@use '../../../../assets/project/components/common/variable.scss' as *;

div#field-wrap-navigationsearchbar {
  padding-top: 0rem;
  margin-top: -1.5rem;

  .c-els-field__icon.c-els-field__icon--right {
    visibility: hidden;
  }

  .c-dropdown-search__option-item {
    font-size: 14px;
    color: $els-color-secondary;
    line-height: 21px;
    padding: 0.75rem;
    cursor: pointer;
  }
}
