.label-field {
  min-width: 40px;

  &--underline {
    display: inline-block;
    border-bottom: 1px dashed #007398;
  }

  .o-els-icon-svg {
    margin-left: 5px;
    padding-top: 5px;
    cursor: pointer;
  }
}
