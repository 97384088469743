.patient-card {
  &__filter > * {
    display: inline-block;
  }

  &__categories {
    width: 400px;
  }

  .patient-card-table {
    .column__description {
      min-width: 300px;
    }

    .column__category {
      min-width: 120px;
    }

    &__action-header {
      display: flex;

      .icon {
        margin-top: 2px;
        margin-left: 5px;
      }
    }

    &__action-field {
      .o-els-icon-svg:first-child {
        margin-right: 5px;
      }
      .discontinue-button:disabled:hover {
        cursor: not-allowed;
      }
    }
  }
}
