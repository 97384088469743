@use '../../../../../assets/project/components/common/variable.scss' as *;

.cardiovascular-assessment {
  &__carotid-peripheral {
    .info-text {
      border-style: solid;
      border-width: 0.025rem;
      border-color: $els-color-primary;
      padding: 1rem 2.25rem 1rem 1rem;

      .o-els-flex-layout--middle {
        padding-top: 0.25rem;
        padding-right: 0.5rem;
        padding-left: 0.25rem;
      }
    }

    .location-dropdown {
      max-width: 360px;
      margin-top: $els-space-2x;
    }
    .generic-image {
      max-width: 360px;
    }
    .o-els-flex-layout--wrap {
      padding-top: 1rem;

      .sng-form-buttons {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }

  &__ankle-brachial {
    .unit {
      vertical-align: bottom;
      margin-left: 12px;
    }

    .chart-input__field {
      max-width: 150px;
      display: inline-block;
      margin-bottom: 0 !important;
    }

    .chart-input__label {
      width: max-content;
    }
  }
}
