#successModal > :first-child {
  width: auto !important;
}

.success-modal {
  width: 500px;

  h1 {
    text-align: center;
  }

  &__button {
    min-width: 300px;
  }

  &__icon {
    width: 128px;
    height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
