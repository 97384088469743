@use '../../../assets/project/components/common/variable.scss' as *;

.charting-action-bar {
  &__close-wrapper {
    cursor: pointer;
    padding: 0.5rem;
    border: 1px solid transparent !important;
    border-radius: 50%;
    background-color: transparent;
    svg {
      display: block;
    }
    &:hover {
      border-color: $els-color-primary !important;
      svg {
        color: $els-color-primary !important;
      }
    }
  }
}

.datetime__time {
  width: 3rem !important;
}
