.medication-reconciliation {
  .prescription-medications-table {
    .column__last-dose {
      min-width: 140px;
    }
  }
  .counter-products-table {
    .column__taken {
      min-width: 110px;
    }
    .column__indication {
      min-width: 110px;
    }
    .column__brand-name {
      min-width: 116px;
    }
    .column__dose {
      min-width: 110px;
    }
  }

  .remedy-products-table {
    .column__taken {
      min-width: 115px;
    }
    .column__product {
      min-width: 110px;
    }
    .column__route {
      min-width: 140px;
    }
  }

  .information {
    .medications-reviewed-by,
    .provider-signature {
      .chart-input {
        &__field {
          display: inline-block;
          vertical-align: baseline;
          margin-left: 10px;
          max-width: 100px;
        }
      }
      .c-els-field {
        display: inline-block;
        vertical-align: baseline;
        margin-left: 10px;
      }
    }
  }
}
