@use '../../../assets/project/components/common/variable.scss' as *;
.multiline-tooltip {
  position: absolute !important;
  z-index: $z-index-tooltip;
  max-width: 500px;

  &__close-btn {
    background-color: white;
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: 16px;
    right: 16px;
  }

  .c-els-flyout__window {
    padding: 0.5rem 2rem 0.5rem 0.5rem;
  }
}
