@use '../../../../../assets/project/components/common/variable.scss' as *;

.therapies {
  &__order-description-text {
    margin-top: -1rem;
  }
  &__times-per-day {
    padding-top: 0.5rem;
    padding-left: 28px;
  }

  .sidebar__container {
    width: calc(100% - #{$width-left-nav});
    max-width: calc(100% - #{$width-left-nav});

    .c-els-card {
      padding: 1rem;
    }
  }

  .c-els-field__input {
    max-width: 500px;
  }

  .section-input {
    max-width: 530px;
  }
}
