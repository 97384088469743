@use '../../../assets/project/components/common/variable.scss' as *;

.chart-history-table {
  &--vertical {
    .c-els-table {
      border-collapse: collapse;
      .c-els-table__cell {
        border-style: solid !important;
        border-width: 1px 1px 1px 2px !important;
        border-color: $els-color-n2;
        min-width: 190px;
      }
    }

    .section-title-none {
      display: none;
    }
  }
  &__entries {
    margin-top: 35px;
  }
  &--deleted .c-els-table__body .c-els-table__row {
    text-decoration: line-through;
  }

  .column__content--overflowed {
    max-width: 100px;
    white-space: nowrap;
    overflow-x: visible;
  }
}
.column__chart-time {
  min-width: 110px;
  width: 180px;
}
.column__note,
.column__delete {
  width: 50px;
}
.row--deleted {
  text-decoration: line-through;
}
.column__content-chart-time {
  min-width: 110px;
}
