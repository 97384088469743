@forward '@els/els-styleguide-core/scss/_tools';
@use '@els/els-styleguide-core/scss/_tools' as *;

// COLOR
$badge-green: $els-color-confirm-on-dark;
$badge-orange: $els-color-warn-on-dark;

// SIZES
$height-breadcrumbs-bar: 72px;
$width-left-nav: 256px;

// Z-INDEX VALUES
$z-index-side-nav: 6000;
$z-index-overlay-block: 6000;
$z-index-tooltip: 6001;
$z-index-header: 7000;
$z-index-grading: 7001;
$z-index-flyout: 7002;
$z-index-sidebar: 8000;
$z-index-tooltip-sidebar: 8001;
$z-index-sticky: 9000;
$z-index-dropdown: 9000;

$header-height: 88px;
$nav-width: 256px;
