@use '../../../assets/project/components/common/variable.scss' as *;

.assessment-detail {
  &__header {
    z-index: 1;
    position: relative;

    & > * {
      padding: 0 $els-space-2x;
    }

    .jumbotron-name {
      height: 95px;
    }

    .student-selection {
      height: 80px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }

    .pending-grade {
      margin-left: $els-space-3o4;
    }
  }

  .information-hollow {
    vertical-align: middle;
    margin-right: 8px;
  }

  &__overall-comments {
    margin-top: $els-space-3x;
    padding: 0 $els-space-2x;

    .c-els-field__label-text {
      font-size: $els-fs-h3;
    }

    .overall-comments__textarea {
      height: auto;
    }

    &--align-center {
      align-items: center;
    }
  }

  &__info {
    position: relative;
  }

  &__footer-actions {
    padding: 0 $els-space-2x;
    margin-top: $els-space-1x1o2;
    margin-bottom: $els-space-3x;
  }

  &__footer {
    background-color: #fff0e4;
    height: 175px;
    padding: 0 $els-space-2x;
    padding-top: $els-space-2x;

    .c-els-button--icon {
      margin-top: $els-space-1o2;

      .o-els-icon-svg {
        left: 1rem;
        height: 1.25rem;
        width: 1.25rem;
        margin-top: -0.75rem;
      }
    }
  }

  .info-text {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 25px 30px;
  }
  .navigate-text {
    font-size: 1rem;
    color: $els-color-n7;
  }
  &__prev-next-student {
    z-index: $z-index-overlay-block + 1;
  }
}
