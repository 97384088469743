@use '../../../../../assets/project/components/common/variable.scss' as *;

.laboratory-orders {
  .sidebar__container {
    width: calc(100% - #{$width-left-nav});
    max-width: calc(100% - #{$width-left-nav});

    .laboratory-sidebar {
      &__reference-input {
        width: 500px;
      }
    }
  }
}
.chart-input__label-tooltip {
  z-index: $z-index-tooltip-sidebar;
}
