@use '../../../../assets/project/components/common/variable.scss' as *;

.mar {
  .view-date-text {
    vertical-align: sub;
  }
  .mar-table {
    .order-start,
    .order-stop,
    .dosage-times {
      min-width: 150px;
    }
    .dose,
    .action {
      min-width: 100px;
    }

    .dosage-time {
      display: flex;
      align-items: baseline;
      .prefix {
        width: 1rem;
        color: $els-color-primary-on-dark;
        text-align: center;
      }
    }
    &__action-field button.sng-button-icon-wrapper[disabled] {
      cursor: not-allowed;
    }
    .c-els-button--link.is-hold {
      color: $els-color-n3;
      cursor: not-allowed;
      border-bottom: none;
      &:hover {
        color: inherit;
      }
    }
  }

  .mar-dosage {
    max-width: 500px;
    &__initials {
      max-width: 100px;
    }
    &__input-offset-time {
      width: 200px;
    }
  }

  .sidebar__container {
    width: calc(100% - #{$width-left-nav});
    max-width: calc(100% - #{$width-left-nav});
  }
}
