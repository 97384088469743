@use '../../../../../assets/project/components/common/variable.scss' as *;

.neurological-assessment {
  .c-els-divider {
    margin-top: 3rem !important;
    margin-bottom: 2rem !important;
  }

  .c-els-field {
    max-width: 500px;
  }

  .column-left {
    order: 1;
  }

  .column-right {
    order: 1;
    @include mq($until: tablet) {
      order: 2;
    }
  }
}
