@use '../../../assets/project/components/common/variable.scss' as *;

.flex-container {
  display: flex;
  overflow: hidden;
}

.chart {
  flex: 8 0;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100vh;
}

.flyout {
  display: flex;
  border-left: solid;
  border-width: $els-thick-keyline-width + $els-thin-keyline-width;
  border-color: $els-color-n2;
  background-color: white;
  overflow: scroll;
  height: 100vh;
  z-index: $z-index-flyout;

  &.show {
    flex: 3 0;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .sticky-flex-container {
      position: sticky;
      top: 0;

      .c-els-button--secondary {
        width: 10rem;
        margin-top: $els-space-1o2;
        height: $els-space-3x;
      }
    }
  }

  &.hide {
    display: none;
  }
}
