@use '../../../assets/project/components/common/variable.scss' as *;

.chart-comment-list {
  &__comment-title {
    color: $els-color-n9;
  }
  &__comment-details {
    align-items: center;
  }
  &__comment-content {
    &:before {
      padding-left: 0;
    }
    white-space: pre-wrap;
  }
  &__chart-comments {
    max-width: 800px;
  }
  &--information-hollow {
    vertical-align: middle;
    margin-right: 8px;
  }
}
