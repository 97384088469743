.c-els-accordion__head {
  min-width: 0;
}

.c-els-badge.c-els-badge--accordion {
  width: auto;
  font-size: 13px;
  bottom: 3px;
  padding-left: 1rem;
  padding-right: 1rem;
  white-space: nowrap;
}
