@use '../../../assets/project/components/common/variable.scss' as *;

.authoring-data-modal {
  p {
    border: 1px solid $els-color-n2;
    max-height: 200px;
    line-break: anywhere;
    overflow-y: scroll;
    padding: 1rem;
  }
}
