.edit-assignment {
  &__content {
    margin: 0 auto;
    padding-bottom: 8rem;
  }

  .info-text {
    width: 14rem;
  }

  .scheduling-wrapper {
    margin-bottom: 3rem;
  }

  .c-datepicker-wrapper {
    z-index: 2;
  }
}
