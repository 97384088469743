.empty-ehr-info {
  &__sidebar {
    .sidebar__content-wrapper {
      padding: 0;
    }
  }
  &__content {
    background-color: #daf5ff;
  }
}
