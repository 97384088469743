@use '../../../../../assets/project/components/common/variable.scss' as *;

.pre-clinical-manager {
  &__nothing-to-see {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 2px solid $els-color-n2;
    border-bottom: 2px solid $els-color-n2;
  }
}
