.pca-flowsheet {
  &__control--short {
    width: 60px;
  }

  &__control--medium {
    width: 200px;
  }

  .pca-flowsheet-document {
    .margin-top-4x1o2 {
      margin-top: 4.5rem;
    }
    .section-title-Time {
      display: none;
    }
  }
}
