.configure-assignment {
  .els-display-name {
    .c-els-field {
      width: 90%;
    }
  }

  .info-text {
    width: 14rem;
  }

  .scheduling-wrapper {
    margin-bottom: 3rem;
  }

  .c-datepicker-wrapper {
    z-index: 2;
  }
}
