@use '../../../assets/project/components/common/variable.scss' as *;

@keyframes showOverlay {
  from {
    visibility: hidden;
  }

  to {
    visibility: visible;
  }
}

@keyframes hideOverlay {
  from {
    visibility: visible;
  }

  to {
    visibility: hidden;
  }
}

@keyframes sidebarSlideIn {
  from {
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes sidebarSlideOut {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(100%, 0, 0);
  }
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-index-sidebar;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.15);

  &.clickable {
    cursor: pointer;
  }

  &.show.animated {
    animation-name: showOverlay;

    .sidebar__container {
      animation-name: sidebarSlideIn;
    }
  }

  &.hide {
    &:not(.animated) {
      visibility: hidden;
    }

    &.animated {
      animation-name: hideOverlay;

      .sidebar__container {
        overflow: hidden;
        animation-name: sidebarSlideOut;
      }
    }
  }

  &__container {
    position: absolute;
    top: 0;
    right: 0;
    width: 1000px;
    max-width: 80%;
    height: 100%;
    background-color: white;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.3);
    overflow-y: auto;
    cursor: default;
  }
  &__divider {
    margin: 2rem -2rem;
    border-top: 2px solid $els-color-n2;
  }
  &__content-wrapper {
    position: relative;
    min-height: 100%;
    padding: 0 2rem;
    overflow-y: auto;

    .c-els-page-loader {
      position: absolute !important;
    }
  }
}

.require-save-sidebar {
  right: 0;
  left: unset;
  width: calc(100% - #{$nav-width});
}
