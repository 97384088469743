@use '../../../../assets/project/components/common/variable.scss' as *;

.breadcrumbs {
  display: flex;
  margin-bottom: -2px;

  > ol li svg {
    margin: 0 0.25rem;
  }

  ol,
  li {
    display: flex;
  }
  &__item {
    height: 1rem;
    font-size: $els-fs-meta;
    box-sizing: content-box;
  }
}
