@use '../../../../../assets/project/components/common/variable.scss' as *;

.els-grading {
  padding-top: 3rem;
  padding-bottom: 8.5rem;
  background-color: #fff0e4;

  &-content {
    margin: 0 auto;
  }

  .c-els-field--radio {
    margin-bottom: 1.875rem;

    .c-els-field__label-text {
      padding-left: 2.5rem;
    }

    .els-field__label-primary-text {
      display: block;
      font-size: 1.25rem;
    }

    .els-field__label-helper-text {
      display: block;
      font-size: 1rem;
    }
  }
}
