@use '../../../../assets/project/components/common/variable.scss' as *;

.pre-clinical-manager {
  &__page {
    textarea {
      resize: vertical;
      height: 120px;
    }

    .form-input {
      &__date {
        width: 200px;
      }

      &--pull-right {
        max-width: 400px;
      }
    }
  }
}

.report {
  &__info {
    background: #fef0e4;
  }

  &__title--deleted {
    color: $els-color-n7;
  }

  &__record {
    .record-item {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-top: 2px solid $els-color-n2;
    }

    .record-item:last-child {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-top: 2px solid $els-color-n2;
      border-bottom: 2px solid $els-color-n2;
    }
  }
}
