.pharmacy-order-entry {
  &__input-dose-field,
  &__input-offset-time {
    width: 200px;
  }

  &__chart-input {
    width: 500px;
  }

  .pharmacy-order-table {
    &__order-time-field {
      min-width: 100px;
    }

    &__detail-field {
      min-width: 300px;
    }
  }
}
