@use '../../../assets/project/components/common/variable.scss' as *;

.related-charts {
  background-color: $els-color-n1;
  margin-top: 8rem;
  margin-bottom: 3rem;
  padding: 1.25rem 3rem;
  transform: translateX(-3rem);
  width: calc(100% + 6rem);

  &__chart-link-wrapper {
    max-width: 25%;
  }

  @include mq($until: tablet) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    transform: translateX(-1.5rem);
    width: calc(100% + 3rem);

    &__chart-link-wrapper {
      max-width: 50%;
    }
  }

  @include mq($from: tablet, $until: desktop) {
    padding-left: 2rem;
    padding-right: 2rem;
    transform: translateX(-2rem);
    width: calc(100% + 4rem);

    &__chart-link-wrapper {
      max-width: 33%;
    }
  }

  &__header {
    margin-bottom: 1.25rem;
  }

  &__references {
    margin-bottom: 5rem;
    max-width: 95%;
  }
}
