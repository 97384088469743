@use '../../../../assets/project/components/common/variable.scss' as *;

.patient-ribbon {
  position: relative;
  border-bottom: 4px solid $els-color-extended-blue-0;

  .c-els-inline-loader {
    position: absolute;
    width: 100%;
    display: flex;
    height: 100%;
    background-color: white;
  }

  .c-els-loader-animation-inline {
    width: 100%;
  }

  &__patient-info {
    &__code_status {
      min-width: 70px;
    }

    @include mq($until: tablet) {
      flex-direction: column;

      & > .o-els-flex-layout__item:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      &__item {
        margin-right: 1rem;
      }
    }
  }
}
