.case-study {
  width: 50%;
  padding: 3rem;

  .datetime {
    &__time {
      height: 37px;
      width: 75px !important;
      margin-left: 1rem;
      text-align: center;
    }

    &__day {
      width: 45px;
      display: inline-block;
      margin-left: 0.5rem;
    }
  }
}
