.h-scroll-container {
  &__outer {
    width: 250px;
    overflow-x: hidden;
  }
  &__content {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    opacity: 0.99; // fix Mac Safari horizontal scroll
    max-width: 99.99%; // fix Mac Safari horizontal scroll
  }
}
