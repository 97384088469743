.nutrition-order-entry {
  &__input-fluid-restriction {
    max-width: 200px;
    width: 100%;
  }

  .nutrition-order-table {
    &__order-time-field {
      min-width: 100px;
    }

    &__detail-field {
      min-width: 300px;
    }
  }
}
