@use '../../../assets/project/components/common/variable.scss' as *;

.overlay-block {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: $z-index-overlay-block;
  top: 0;
  left: 0;
  background-color: white;
  opacity: 0.85;
}
