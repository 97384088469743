@import './variable';

#app {
  // icon customization in input field field, dropdown search
  .c-els-field__icon--left {
    width: 1rem;
    height: 1rem;
    margin-top: -8px;
    margin-left: 2px;
    fill: $els-color-secondary;
  }
  .c-els-field--radio {
    margin-top: 0.5rem;
  }
  .c-els-field--checkbox {
    margin-top: 0.5rem;
  }

  // overwrite c-els-card css to return origin because this css was overwritten in @els/els-component-assessment-react by incident
  .c-els-card {
    padding: 2rem;
    background: white;
    &--small {
      padding: 1rem;
    }
    &--no-padding {
      padding: 0;
    }
  }
}

div[name='courseForm'] {
  #field-wrap-use-app-params-cb {
    display: none;
  }
  #field-wrap-isbn-select {
    display: none;
  }
}

ol.c-els-list--full-width {
  .c-els-list__item {
    width: 100%;
    display: flex;
    padding: $els-space-1o2 0;
    margin: 0;
  }
  & > .c-els-list__item:before {
    padding-left: 0;
  }
}

.c-els-security__message {
  height: 100vh;
  text-align: center;
}
.c-els-button {
  &--active {
    background-color: $els-color-primary;
    border-color: $els-color-primary;
  }
  &--middle {
    display: flex;
    align-items: center;
  }
  &--link {
    .o-els-icon-svg {
      margin-right: 0.5rem;
    }
    &.c-els-button--with-icon {
      border-bottom: none !important;
      &-right {
        .o-els-icon-svg {
          margin-left: 0.5rem;
        }
      }
      .c-els-button__text {
        margin-top: 2px;
        border-bottom: 2px solid transparent;
      }
      &:hover .c-els-button__text {
        border-color: $els-color-primary;
      }
    }
    &:disabled {
      cursor: not-allowed;
      color: #cecece;
      &.c-els-button--with-icon {
        &:hover .c-els-button__text {
          border-color: transparent !important;
        }
      }
    }
  }
  &--icon {
    &.c-els-button--icon-1x > svg {
      width: 1rem !important;
      height: 1rem !important;
      top: unset !important;
      right: 0.75rem !important;
      margin-top: 0 !important;
    }
  }
}

.c-cancel-navigation {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  &__text {
    font-size: $els-fs-meta;
    color: $els-color-n9;
  }
}

.c-els-list__item.sng::before {
  padding-left: 0 !important;
}

.c-els-button.sng-button {
  min-width: 100px;
}

.c-els-divider {
  color: $els-color-n2;
}

.c-els-table {
  &--border-bottom {
    border-bottom: 1px solid $els-color-n2;
  }
  &__cell {
    .c-els-field--radio {
      margin-top: 0.125rem !important;
    }
  }
}

// disable calendar icon when date field is disabled
.c-els-field--disabled {
  .c-datepicker-icon {
    cursor: not-allowed;
  }
}

.sng-button-icon-wrapper {
  border: none;
  background: transparent;
  cursor: pointer;
}

.scale-wrapper {
  .scale__title {
    width: 100%;
  }
  .scale__score {
    width: 100%;
  }

  .score-box {
    display: flex;
  }

  .score-tag {
    margin-left: auto;
    padding: 0.5rem 1.5rem;
    background-color: $els-color-extended-blue-0;
    color: $els-color-extended-blue-9;

    &.scale__delivery {
      position: absolute;
      font-weight: bold;
      right: 0;
    }
  }

  .special-character {
    color: $els-color-secondary-on-dark;
    margin-right: 13px;
  }

  .scale-divider {
    margin-top: -2rem !important;
  }

  .roll-up-content {
    padding: 0.5rem 0 0.5rem 0.5rem;
  }

  .scale-roll-up--highlight {
    background-color: $els-color-extended-blue-0;
    .roll-up-text {
      color: $els-color-extended-blue-9;
      font-weight: bold;
    }
    border: 1px solid $els-color-extended-blue-10;
  }

  .c-els-table {
    max-width: 350px;
  }
}
