@use '@els/els-styleguide-core/scss/_tools' as *;

.round-icon {
  border-radius: 50%;
  padding: 3px;
  border: none;
  margin-right: 8px;
}
.round-icon--pass {
  background-color: $els-color-confirm;
}
.round-icon--fail {
  background-color: $els-color-warn;
}

.round-icon--administered {
  margin-right: 0;
  background-color: $els-color-secondary;
}
