@use '../../../assets/project/components/common/variable.scss' as *;

.grading-chart {
  position: relative;
  &__body {
    position: fixed;
    bottom: 0;
    right: 0;
    width: calc(100% - #{$width-left-nav});
    padding: 1rem 1.5rem;
    background-color: white;
    z-index: $z-index-grading;
    border-top: 2px solid $els-color-n3;
    filter: drop-shadow(0px -1px 4px rgba(0, 0, 0, 0.15));
  }
}
.sidebar {
  .grading-chart {
    position: sticky;
    bottom: 0;
    &__body {
      position: relative;
      width: 100%;
    }
  }
}
