@use '../../../assets/project/components/common/variable.scss' as *;

.student-list {
  .c-els-field--checkbox {
    margin-top: 0 !important;
  }
  &__table {
    background-color: $els-color-n1;
    max-height: 400px;
    overflow-y: auto;
    padding-top: 0;
  }
  &__table-header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $els-color-n1;
    padding: 1.5rem 1rem 0.5rem 1rem !important;
  }
  &__row {
    border-top: 2px solid $els-color-n2;
  }
}
