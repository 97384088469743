@use '../../../../assets/project/components/common/variable.scss' as *;

$nav-label-width: 200px;

@keyframes hideLeft {
  from {
    margin-left: 0;
  }
  to {
    margin-left: -$nav-width;
    display: none;
  }
}

@keyframes showLeft {
  from {
    margin-left: -$nav-width;
  }
  to {
    margin-left: 0;
  }
}

.nav {
  height: 100%;
  position: relative;

  > div:first-child {
    width: $nav-width;
    height: 100%;
    background-color: $els-color-n0;
    overflow-x: hidden;
    position: relative;
    z-index: $z-index-side-nav;

    &.animated.show {
      -webkit-animation-name: showLeft;
      animation-name: showLeft;
    }

    &.animated.hide {
      -webkit-animation-name: hideLeft;
      animation-name: hideLeft;
    }
  }

  &__animation {
    display: flex;
  }

  &__wrapper {
    order: 1;
    flex-shrink: 0;
    width: $nav-width;
    padding-bottom: 1rem;
    border-bottom: $els-thick-keyline-width solid $els-color-n3;
  }

  &__header {
    display: flex;
    align-items: center;
    height: 72px;
    padding: 1rem;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    min-height: 40px;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    color: $els-color-n9 !important;
    border: none;

    &__label {
      width: $nav-label-width;
    }

    &--active,
    &:hover {
      background-color: white;
      font-weight: bold;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-left: 0.25rem solid $els-color-secondary;
      }
    }
  }
  .nav-item--top {
    margin-top: -0.5rem;
    margin-bottom: 1rem;
  }
}

.c-navigation {
  width: 16rem;
  max-width: 16rem;
  height: 100%;
  background-color: $els-color-n0;
}
