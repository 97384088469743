.launch {
  &__launch-button {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    padding: 1rem 3rem;
  }
}
