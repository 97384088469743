@use '../../../assets/project/components/common/variable.scss' as *;

.case-study-info {
  padding-bottom: $els-space-1x * 7;

  &__header {
    min-height: 160px;
    background-color: $els-color-extended-blue-0;

    .o-els-icon-svg--1x {
      width: $els-space-1x !important;
      height: $els-space-1x !important;
      top: 22px !important;
      left: $els-space-3o4 !important;
    }

    .o-els-icon-svg--sub {
      position: relative;
      top: -$els-space-1o4;
    }
    .note {
      max-width: 270px;
      position: relative;
      top: -$els-space-1o4;
      margin-left: $els-space-1o2;
      line-height: 16px;
    }
  }

  &__tab-group {
    position: relative;
    margin: $els-space-1x 0rem;
  }
}

@media (max-width: 1300px) {
  .case-study-info__title {
    font-size: 24px;
  }
  .c-els-tab-group__text {
    font-size: 16px;
  }
}
