@use '../../../assets/project/components/common/variable.scss' as *;

.assessment-tile.c-els-card {
  margin: 0 auto;
  width: 240px;
  height: 140px;
  background-color: white;
  box-shadow: 0 2px 10px 0 rgba(170, 170, 170, 0.25);
  padding-left: $els-space-1x1o2;
  padding-right: $els-space-1x1o2;
  .o-els-flex-layout {
    height: 100%;
  }
}
