@use '@els/els-styleguide-core/scss/tools' as *;

$color-level-emergency-call: $els-color-extended-purple-2 !important;
$color-level-0: $els-color-n3 !important;
$color-level-1: $els-color-extended-yellow-2 !important;
$color-level-2: $els-color-extended-orange-2 !important;
$color-level-3: $els-color-extended-red-2 !important;

#vital-signs-adds {
  .c-els-list__item:before {
    color: $els-color-n10 !important;
  }
}

.u-els {
  &--full-height {
    height: 100%;
  }
}

.u-vital-signs-adds {
  &__score-range {
    &--highlight {
      background-color: $els-color-extended-blue-0 !important;
      border-color: $els-color-extended-blue-10 !important;
    }
  }
}

.c-vital-signs-adds {
  &__total-score {
    margin-left: auto;
    margin-right: auto;
    padding: 0.5rem 1.5rem;
    background-color: $els-color-n1;
  }

  &__badge {
    position: unset !important;
    bottom: unset !important;
    margin-left: 14px;
  }

  &--full-height {
    height: 100%;
  }

  &--level-emergency-call {
    background-color: $color-level-emergency-call;
  }

  &--level-0 {
    background-color: $color-level-0;
  }

  &--level-1 {
    background-color: $color-level-1;
  }

  &--level-2 {
    background-color: $color-level-2;
  }

  &--level-3 {
    background-color: $color-level-3;
  }
}
