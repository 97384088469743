@use '../../../assets/project/components/common/variable.scss' as *;

.sng-product-filter-search {
  text-align: right;
  height: $els-space-2x;
  line-height: $els-space-2x;
  display: contents;

  &__content {
    text-align: right;
    padding: 0 $els-space-3o4;
    position: relative;
  }

  &__divider {
    width: $els-space-2x;
    position: relative;
    display: inline-block;
  }

  &__reset {
    padding-left: $els-space-3o4;
  }

  &__input {
    text-align: left;
    width: 5 * $els-space-5x;
    .c-els-flyout .c-els-flyout__window {
      min-height: 4 * $els-space-5x;
    }
    .c-dropdown-search__option {
      min-height: 3 * $els-space-5x;
    }
  }

  &__tag.c-tag-group {
    display: inline-block;

    .c-tag-group__tag {
      color: $els-color-n0;
      background-color: $els-color-secondary;
      margin: 0 $els-space-3o4;
    }

    .c-els-button__text .c-tag-group__tag-icon {
      color: $els-color-n0 !important;
    }
  }
}

.c-els-flyout-portal {
  .c-els-flyout-wrapper {
    z-index: $z-index-dropdown;
  }
}
