@use '../../../assets/project/components/common/variable.scss' as *;

.quiz-questions-result {
  .answer-indicator {
    &--correct,
    &--incorrect {
      margin-top: 17px;
    }
  }
  .rationale {
    &__text {
      color: $els-color-n7;
    }

    &__text.u-els-margin-top {
      color: $els-color-n9;
    }
  }
  &__title {
    font-size: $els-fs-h3;
  }
}
