@use '../../../assets/project/components/common/variable.scss' as *;

.sng-multi-select-search {
  max-width: 500px;
  margin-top: 1rem;
  .sng-multi-select-search__dropdown {
    .c-els-field--radio {
      margin-top: 0.25rem !important;
    }
  }
  &__select-all-option {
    position: sticky;
    top: 0;
    background: white;
    border-bottom: 1px solid $els-color-n2;
    z-index: 2;
  }
  &__tag-name-group.c-tag-group {
    margin-top: -0.75rem;
    .c-tag-group__tag {
      display: inline-flex;
      min-width: 100px;
      font-size: 1rem;
      margin: 0.75rem 1rem 0 0;
      padding: 0.5rem 1rem;
      color: white;
      background-color: $els-color-primary;
      border-radius: 0;
    }
    .c-tag-group__tag-icon {
      box-sizing: content-box;
      color: white !important;
      width: 1rem !important;
      height: 1rem !important;
      padding-left: 0.5rem !important;
    }
  }
}
