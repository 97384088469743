@use '../../../assets/project/components/common/variable.scss' as *;

.instructor-page-wrapper {
  &__content-wrapper {
    position: relative;
    min-width: 0;
    max-width: 1200px;
  }

  &__hide-content {
    position: absolute;
    z-index: $z-index-side-nav;
    top: $height-breadcrumbs-bar;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.85;
    cursor: pointer;
  }
}
