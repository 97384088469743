.allow-event {
  display: inline-block;
  position: relative;
  cursor: not-allowed;
  &__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
