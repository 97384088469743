@use '../../../assets/project/components/common/variable.scss' as *;
$normal-size: 1rem !important;

.section-box {
  border: solid 1px $els-color-n3;
  padding: 1rem 2rem;
  margin-bottom: 2rem;
}

.case-study-name {
  margin-bottom: 2rem;
}

.section-quiz-question {
  .o-els-container.u-els-margin-top-3x {
    margin-top: 0 !important;
  }

  .c-els-field.u-els-margin-bottom-1x1o2 {
    margin-bottom: 0.5rem !important;
  }

  .answer-indicator {
    height: $normal-size;
    width: $normal-size;
    margin-top: 7px;
  }

  .c-els-field {
    &__label-text {
      font-size: $normal-size;
    }
  }

  .question-prompt {
    &__content {
      font-size: $normal-size;
    }
  }

  .rationale__text {
    font-size: $normal-size;
  }
}
