@use '../../../assets/project/components/common/variable.scss' as *;

$menu-width: 256px;

.case-study-landing {
  .menu {
    &__wrapper {
      width: $menu-width;
      padding: 3rem 0 1rem 0;
      border-bottom: $els-thick-keyline-width solid $els-color-n3;
    }

    &__item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      min-height: 40px;
      padding: 0.5rem 0.5rem 0.5rem 1rem;
      color: $els-color-n8;
      font-style: italic;
      border: none;
      cursor: default;

      .o-els-icon-svg {
        margin-left: 1rem;
      }

      &--completed {
        font-style: normal;
      }

      &--active {
        background-color: white;
        font-weight: bold;
        font-style: normal;
        color: $els-color-n9;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          border-left: 0.25rem solid $els-color-secondary;
        }
      }
    }
  }

  .case-study-phases {
    min-width: 250px;
    .case-study__diagnosis {
      font-size: 14px;
      color: $els-color-secondary;
    }
  }

  .info {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-left: $els-thick-keyline-width solid $els-color-n3;
    width: 280px;
    min-height: 100vh;

    @include mq($from: desktop) {
      width: 410px;
    }

    &__patient-name {
      font-size: 20px;
      margin-top: 1rem;
    }

    &__patient-gender,
    &__patient-age {
      text-align: center;
      font-size: 12px;
    }

    &__patient-age {
      margin-right: 5px;
    }

    &__patient-gender {
      margin-left: 5px;
    }

    .icon {
      width: 45px;
      height: 45px;
      margin-bottom: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $els-color-n0;
      border-radius: 50%;
    }

    .c-els-button {
      width: 244px;
      height: 48px;
    }

    &__progress {
      width: 244px;
      margin-top: 5px;
    }

    &__progress-chart,
    &__progress-time {
      width: 116px;
      background-color: $els-color-n0;
    }

    &__progress-title {
      font-size: 20px;
      margin-top: 2rem;
    }

    &__progress-phases {
      width: 244px;
      background-color: $els-color-n0;
      margin-top: 1rem;
      text-align: center;
    }

    &__progress-label {
      font-size: 12px;
      margin-top: 10px;
      text-align: center;
    }

    &__progress-content {
      font-size: 30px;
      text-align: center;

      &--small {
        font-size: 16px;
      }
    }
  }
}
