@use '@els/els-styleguide-core/scss/_tools' as *;
@use '@els/els-styleguide-core/scss/utilities' as *;

$els-fractions: 1 2 3 4 5 6 !default;

@mixin u-flex-grids($space, $breakpoint: null) {
  @each $numerator in $space {
    .u-flex-grid-#{$numerator}x {
      margin-top: -$numerator + rem !important;
      margin-left: -$numerator + rem !important;
      > .o-els-flex-layout__item {
        margin-top: $numerator + rem !important;
        padding-left: $numerator + rem !important;
        &.c-els-divider {
          margin-left: $numerator + rem !important;
        }
      }
    }
    .u-flex-gutter-#{$numerator}x {
      margin-left: -$numerator + rem !important;
      > .o-els-flex-layout__item {
        padding-left: $numerator + rem !important;
        &.c-els-divider {
          margin-left: $numerator + rem !important;
        }
      }
    }
    .u-flex-row-gap-#{$numerator}x {
      margin-top: -$numerator + rem !important;
      > .o-els-flex-layout__item {
        padding-top: $numerator + rem !important;
      }
    }
  }
}
@include u-flex-grids($els-fractions);

.u-cursor-pointer {
  cursor: pointer;
}
.u-outline-none {
  outline: none;
}

.u-flex-column {
  flex-direction: column;
}

.u-flex-column\@mobile {
  @include mq($until: mobile) {
    flex-direction: column;
  }
}

.u-text-capitalize {
  text-transform: capitalize;
}

.u-text-lowercase {
  text-transform: lowercase;
}

.u-text-word-break {
  word-break: break-word;
  hyphens: auto;
}

.u-text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.u-text-nowrap {
  white-space: nowrap;
  * {
    white-space: nowrap;
  }
}

@mixin multiLineEllipsis($maxLines: 2) {
  @supports (-webkit-line-clamp: $maxLines) {
    overflow: hidden;
    white-space: initial;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: $maxLines;
    -webkit-box-orient: vertical;
  }
}

.u-position-fixed {
  position: fixed;
}

.u-position-relative {
  position: relative;
}

.u-valign-top {
  vertical-align: top;
}

.u-valign-middle {
  vertical-align: middle;
}

.u-valign-bottom {
  vertical-align: bottom;
}

.u-display-table {
  display: table;
}

.u-display-table-row {
  display: table-row;
}

.u-display-table-cell {
  display: table-cell;
}

.u-section-no-gutter {
  margin-left: -$els-space-2x;
  margin-right: -$els-space-2x;
  @include mq($until: tablet) {
    margin-left: -$els-space-1x1o2;
    margin-right: -$els-space-1x1o2;
  }
  @include mq($until: mobile) {
    margin-left: -$els-space;
    margin-right: -$els-space;
  }
}

@mixin utility-font-sizes($breakpoint: null) {
  @each $class, $size in $els-font-sizes {
    .u-els-font-size-#{$class}#{$breakpoint} {
      font-size: $size !important;
    }
  }
}

@if (variable-exists(mq-breakpoints-reversed)) {
  @each $els-bp-name, $els-bp-value in $mq-breakpoints-reversed {
    @include mq($until: $els-bp-name) {
      @include utility-font-sizes(\@#{$els-bp-name});
    }
  }
}

.sng-blank-page {
  min-height: 400px;
}

.sng-placeholder {
  border: 1px solid #cecece;
}

// row-gap for flex not supported yet on Chrome
.o-els-flex-layout--row-gap {
  margin-top: -1rem !important;
  .o-els-flex-layout__item {
    margin-top: 1rem !important;
  }

  .error-required .o-els-flex-layout__item {
    margin-top: 0 !important;
  }
}

.u-overflow {
  &--hidden {
    overflow: hidden;
  }

  &-x--auto {
    overflow-x: auto;
  }
  &-y--hidden {
    overflow-y: hidden;
  }
}

.u-word-wrap--break-word {
  word-wrap: break-word;
}

.u-white-space--pre-wrap {
  white-space: pre-wrap;
}

.u-border--circle {
  border-radius: 50%;
}
